import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { checkEmail } from '../form.helpers';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValidEmail = checkEmail(control.value);
    const valid = isValidEmail || !control.value;

    return !valid ? { email: 'error' } : null;
  };
}
